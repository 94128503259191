const ADR_API = process.env.VUE_APP_API_URL; 
const FACILITATOR_MODULE = process.env.VUE_APP_FACILITATOR_MODULE;
const DISPUTE_MODULE = process.env.VUE_APP_DISPUTE_MODULE;
const FACILITATOR_USER_ID_FIELD = process.env.VUE_APP_FACILITATOR_USER_ID_FIELD;
const FACILITATOR_DESCRIPTION = process.env.VUE_APP_FACILITATOR_DESCRIPTION;

import axios from 'axios';

import { createStore } from 'vuex';

const state = {
  dataLoaded:false,
  accountId:1,
  adr_dispute:{},
  ubt_dispute:{},
  adr_chairperson:{},
  adr_facilitator:{},
  ubt_mediator:{},
  dbg:{ADR_API,FACILITATOR_USER_ID_FIELD,FACILITATOR_MODULE,FACILITATOR_USER_ID_FIELD,FACILITATOR_DESCRIPTION}
};

const getters   = {
  facilitators(state) {
    return state[FACILITATOR_MODULE];
  },
  facilitatorDescription(state) {
    return FACILITATOR_DESCRIPTION;
  },
  disputes(state) {
    return state[DISPUTE_MODULE];
  },
  facilitatorUserIdField(state) {
    return FACILITATOR_USER_ID_FIELD;
  },
  facilitatorModule(state) {
    return FACILITATOR_MODULE;
  },
  disputeModule(state) {
    return DISPUTE_MODULE;
  },

  facilitatorDates(state) {
    let dates = {};
    for (let c in state[FACILITATOR_MODULE]) {
      let facilitator = state[FACILITATOR_MODULE][c];
      let facilitatorDates = facilitator.dates;
      for (let d in facilitatorDates ) {
        let facilitatorDate = facilitatorDates[d];
        dates[d] = {...facilitatorDate}
      }
    }
    console.log('@@@@@@@facilitatorDates:',dates);
    return dates;
  },
};
const mutations = {};
const actions   = {
  async assignFacilitator(store,params) {
    console.log('Assign Facilitator:',store,params);
    let postParams = {};
    postParams.dispute_id = params.dispute_id;
    postParams.date = params.date;
    postParams[FACILITATOR_USER_ID_FIELD] = params.facilitatorUserId;
    let assignResponse = await axios.post(`${ADR_API}/action/assign_facilitator`,postParams,{});
    let responseData = assignResponse.data;
    console.log('assignResponse:',responseData);
    let facilitator = responseData[FACILITATOR_MODULE];
    let dispute = responseData[DISPUTE_MODULE];
    store.state[FACILITATOR_MODULE][facilitator.id] = {...facilitator};
    store.state[DISPUTE_MODULE][dispute.id] = {...dispute};
  },
  async loadInitialData(store,{facilitatorUserId}) {
    await store.dispatch('loadDisputes',{facilitatorUserId});
    await store.dispatch('loadFacilitatorData',{facilitatorUserId});
    store.state.dataLoaded = true;
  },
  async loadDisputes(store,{}) {
    let responseKey = `${DISPUTE_MODULE}s`;
    let DISPUTE_URL = `${ADR_API}/${responseKey}`;
    console.log('GETTING DISPUTE URL:',`${ADR_API}/${responseKey}`);
    let disputeResponse = await  axios.get(DISPUTE_URL);
    console.log('disputeResponse.data',disputeResponse.data);
    let disputes = disputeResponse.data[responseKey];
    for (let d = 0 ; d < disputes.length ; d ++ ) {
      let dispute = disputes[d][DISPUTE_MODULE];
      console.log('dispute:',dispute);
      store.state[DISPUTE_MODULE][dispute.id] = {...dispute};
    }
  },
  async loadFacilitatorData(store,params) {   
    console.log('params:',params);
    let facilitatorUserId = params.facilitatorUserId;
    console.log('FACILITATOR_MODULE:',FACILITATOR_MODULE);
    let accountId = store.state.accountId;
    console.log('facilitatorUserId:',facilitatorUserId) ;
    let URL = `${ADR_API}/${FACILITATOR_MODULE}/${accountId}/${facilitatorUserId}`;
    console.log('URL:',URL) ;
    let facilitatorResponse = await  axios.get(URL);
    console.log('facilitatorResponse:',facilitatorResponse);
    
    for (let c in facilitatorResponse.data ) {
      let facilitator = facilitatorResponse.data[c];
      console.log('facilitator:',facilitator[FACILITATOR_MODULE]);
      store.state[FACILITATOR_MODULE][facilitator[FACILITATOR_MODULE].id] = {...facilitator[FACILITATOR_MODULE]};
    }    
    console.log('GOT FACILITATORS!',facilitatorResponse);
  },
  async addRemoveAvailableDate (store,{date,facilitatorUserId}) {    
    let key = `${date}-${facilitatorUserId}`;
    let action = 'add';
    let source = 'available';
    if (store.state[FACILITATOR_MODULE][facilitatorUserId].dates[key]) {
      action = 'remove';
      source = store.state[FACILITATOR_MODULE][facilitatorUserId].dates[key].source;
    }
    let accountId = store.state.accountId;
    if (source === 'available') {
      let postParams = {id:key,available:true,date:date,active:false,disputeId:0,facilitatorUserId:facilitatorUserId,action:action};
      postParams[FACILITATOR_USER_ID_FIELD] = facilitatorUserId;
      let newFacilitatorDates = await axios.put(`${ADR_API}/${FACILITATOR_MODULE}_date/${accountId}/${facilitatorUserId}`,postParams,{});
      let updatedDates = newFacilitatorDates.data.dates;
      let oldDateKeys = Object.keys(state[FACILITATOR_MODULE][facilitatorUserId].dates);
      let keepDate = {};
      for (let d in updatedDates) {
        let date = updatedDates[d];
        keepDate[d] = true;
        store.state[FACILITATOR_MODULE][facilitatorUserId].dates[d] = {...date};
      }
      for (let k = 0; k < oldDateKeys.length ; k ++ ) {
        let key = oldDateKeys[k];
        if (!keepDate[key]) {
          delete store.state[FACILITATOR_MODULE][facilitatorUserId].dates[key];
        }
      }
    }          
  }

};

const modules   = {};
const store     = {state,getters,mutations,actions,modules};

window.state = state;

export default createStore(store);

