<template>
  <template v-if="dataLoaded">
  <HomeView />
  </template>
</template>

<script>

import {mapState,mapActions,mapGetters} from 'vuex';

import HomeView from './views/HomeView.vue';

export default {
  components: {
    HomeView,
  },
  data() {
    return {
      currentDate:null,
    }
  },
  mounted() {
    this.init({source:'mounted',
      facilitatorUserId:5
    })
  },
  watch:{
   facilitatorUserId(newFacilitatorID,oldFacilitatorID)  {
    console.log('WATCH: newFacilitatorID:',newFacilitatorID);
    this.init({facilitatorUserId:newFacilitatorID});
   }
  },
  computed:{
    ...mapState(['chairpersons','facilitatorDates','dataLoaded']),
    ...mapGetters(['facilitators','disputes']),
    hasChairpersonAndData() {
      return ((this.facilitatorUserId !== undefined) && (this.dataLoaded === true));
    },
    facilitatorUserId() {
      return this.$route.params.facilitatorUserId;
    },
  },
  methods:{
    ...mapActions(['loadInitialData']),
    init({facilitatorUserId}) {
      console.log('facilitatorUserId:',facilitatorUserId);
      this.loadInitialData({facilitatorUserId});
    }
  }
}

</script>

<style>

:root{
  --theme-color: #47686A;
  --theme-color-light: #719c9f;
  --theme-color-dark: #344c4d;
  --theme-color-off-white: rgba(143, 158, 159, 0.454);
  --active-menu-color: #D7C2AB;
  --logo-background-color: #D7C2AB33;
}

.btn-default{
    background-image: linear-gradient(var(--theme-color-light), var(--theme-color) 60%, var(--theme-color-dark));
    color: white;
    border-radius: 2px;
    border: 1px solid var(--theme-color);
}

</style>
