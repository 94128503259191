<template>
    <div  class="adr-allocate-facilitator-container">
    <div class="adr-allocate-calendar">
        <ADRCalendarView @on-day-click="onDayClick" @on-available-facilitator-click="onAvailableFacilitatorClick" />
    </div>
    <div class="adr-allocate-disputes">
        <ADRUnallocatedDisputes :date="currentDate" :facilitatorUserId="currentFacilitatorUserId"/>
    </div>
    </div>
</template>


<script>

import ADRCalendarView from '@/views/ADRCalendarView.vue';

import ADRUnallocatedDisputes from '@/views/ADRUnallocatedDisputes.vue';

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

export default {
  components: {ADRCalendarView,ADRUnallocatedDisputes},
  props: {  
  },
  data() {
    return {
      currentDate:new Date(),
      currentFacilitatorUserId:0
    }
  },
  created() {

  },
  mounted() {

  },
  computed:{
    ...mapGetters([]),
  	...mapState([]),
  },
  methods:{
  	...mapActions(['assignFacilitator']),
  	...mapMutations([]),
    onAvailableFacilitatorClick({facilitatorUserId}) {
      console.log('RECV:facilitatorUserId:',facilitatorUserId);
      this.currentFacilitatorUserId = facilitatorUserId;
    },
    onDayClick(date) {
      console.log('CLICKED ON DATE!',date);
      this.currentDate = date;
    }
  }
 }
</script>
<style>

div.adr-allocate-facilitator-container{
  /* border: 1px solid #ccc; */
  display:flex;
  min-height: 800px;
  max-height: 800px;
}

div.adr-allocate-disputes{
  flex:1;
  border: 1px solid #ccc;
}

div.adr-allocate-calendar{
  flex:2;
  border: 1px solid #ccc;
  max-width: 800px;
  
}

</style>


