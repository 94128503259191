<template>
    <div  class="adr-unallocated-dispute-container" :class="activeClass">
      <!-- <div class="tmp">{{dispute.id}}</div> -->
      <div class="dispute-reference">{{dispute.reference}}</div>
      <!-- <div class="allocate-dispute-button">+</div> -->
    </div>
</template>


<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

export default {
  components: {},
  props: {  
    dispute:{type:Object,required:true},
    active:{type:Boolean,required:true}
  },
  data() {
    return {
    }
  },
  created() {

  },
  mounted() {

  },
  computed:{
    ...mapGetters([]),
  	...mapState([]),
    activeClass() {
      return this.active === true ? 'active' : 'not-active';
    }
  },
  methods:{
  	...mapActions([]),
  	...mapMutations([])
  }
 }
</script>
<style>

	div.adr-unallocated-dispute-container{
    display:flex;
    align-items:center;
    margin-bottom:2px;
  }

div.adr-unallocated-dispute-container{
  color:var(--theme-color-dark);
  cursor: pointer;
  font-weight: bold;
  background-color:var(--theme-color-off-white);
  padding:5px;
  margin-left:5px;
  margin-right: 5px;
  border-radius: 3px;
}

div.adr-unallocated-dispute-container.active{
  background-color:var(--theme-color-dark);
  color:white;
  font-weight: bold;
  min-height: 30px;
  border: 1px solid var(--theme-color-dark);
}

div.adr-unallocated-dispute-container:hover{
    background-color:var(--theme-color);
    color:white;
}

div.dispute-reference{
  flex:1;
}


div.tmp{
  flex:1;
  display:flex;
  max-width: 30px;
  align-items: center;
  justify-content: center;

}

div.dispute-reference{
  flex:2;
  align-items: center;
  justify-content: center;
}

div.allocate-dispute-button{
  min-width: 30px;
  display:flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;
}

</style>


