import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ADRFacilitatorDatesAvailable  from '../views/ADRFacilitatorDatesAvailable.vue';
import ADRCalendarView from '../views/ADRCalendarView.vue';
import ADRAllocateFacilitator from '../views/ADRAllocateFacilitator.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path:'/allocate_facilitator/:userId',
    name:'ADRAllocateFacilitator',
    component:ADRAllocateFacilitator
  },
  {
    path:'/calendar/:facilitatorUserId',
    name:'ADRCalendarView',
    component:ADRCalendarView
  },
  {
    path:'/dates/:facilitatorUserId',
    name:'ADRFacilitatorDatesAvailable',
    component:ADRFacilitatorDatesAvailable
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
