<template>
    <div  class="adr-facilitator-dates-available-container" style='width: 700px'>
      <div class="adr-available-calendar-container">
      <VCalendar :attributes="attributes"   @dayclick="onDayClick"  @on-available-facilitator-click="onAvailableFacilitatorClick">
        
      </VCalendar>  
      </div>
      <div class="calendar-legend-container">
      <div class="ubt-legend legend-available">
       Available Dates (Click to set / unset)
      </div>
      <div class="ubt-legend  legend-confifmed">
      Confirmed Mediations
      </div>
      <div class="ubt-legend  legend-tentative">
      Tentative Mediations
      </div>
       <div class="ubt-legend  legend-error">
       Mediations Requiring Urgent Attation
      </div>
      </div>
      </div>      
</template>

<script>

import {mapState,mapActions,mapGetters} from "vuex";


export default {
  components: {

  },
  props: {  
  },
  data() {
    return {
      dates:[],

    }
  },
  created() {

  },
  mounted() {




  },
  methods:{
    ...mapActions(['addRemoveAvailableDate']),
    onAvailableFacilitatorClick(evt) {
      
    },
    onDayClick(evt) {
      this.addRemoveAvailableDate({date:evt.id,facilitatorUserId:this.facilitatorUserId});
      console.log('onDayClick:',evt);
    },
    getTentativeLabel(date) {
      let disputeReference = this.disputes[date.dispute_id].reference;
      console.log('GETTING LABEL FOR:',date);
      return `Hearing tentative for: ${disputeReference}`; 
    },
    getLabelForConfirmedDate(date) {
      console.log('GETTING LABEL FOR:',date);
      let disputeReference = this.disputes[date.dispute_id].reference;
      return `Hearing confirmed for: ${disputeReference}`;
    }
  },
  computed:{
    ...mapGetters(['facilitatorDates','facilitatorUserIdField','disputes','facilitators']),
  	...mapState([]),
    themeStyles() {
      return {background: 'linear-gradient(to bottom right, #ff5050, #ff66b3)'}
    },
    facilitatorName() {
      let facilitatorUserId = this.facilitatorUserId;
      return this.facilitators[facilitatorUserId].name;
    },
    facilitatorUserId() {
      console.log('facilitatorUserId:',this.$route);
      let facilitatorUserId = this.$route.params.facilitatorUserId;
      return facilitatorUserId;
    },
    assignedAndActive() {
      return {
        highlight: 'red',
      }
    },
    assignedDates() {
      return {
        highlight: 'green',
        dates: [
          new Date(2024, 1, 11),
          new Date(2024, 1, 18)
         ],
        }
      },
      availableDates() {
        return {
          highlight: 'blue',
          dates: [
            new Date(2024, 1, 10), 
            new Date(2024, 1, 13)
          ],
        }
    },
    attributes() { 
      let res = [];
      let red = [];
      let green = [];
      let orange = [];
      let blue = [];
      for (let d in this.facilitatorDates ) {
        let date = this.facilitatorDates[d];
        let dateFacilitatorId = Number(date[this.facilitatorUserIdField]);
        let currentFacilitatorUserId = Number(this.facilitatorUserId);
        console.log('date.dateFacilitatorId:',dateFacilitatorId);
        console.log('currentFacilitatorUserId:',currentFacilitatorUserId);
        if (dateFacilitatorId === currentFacilitatorUserId) {
          console.log('date:',date);

        if (date.dispute_id === 0 && date.source === 'available') {
          blue.push({dates:new Date(date.date),highlight:'blue',popover:{label:'Date set as avilable'}});
          // bluePopovers.push({popover:{label:'Available'}});
        } else 
        if (date.dispute_id !== 0 && date.source === 'assigned') {
          orange.push({dates:new Date(date.date),highlight:'orange',popover:{label:this.getTentativeLabel(date)}});
        } else 
        if (date.dispute_id !== 0 && date.source === 'booked') {
          green.push({dates:new Date(date.date),highlight:'green',popover:{label:this.getLabelForConfirmedDate(date)}});
        }
          }
        }
        
        // res.push({highlight:'blue',dates:blue});
        // res.push({highlight:'red',dates:red});
        // res.push({highlight:'green',dates:green});
        // res.push({highlight:'orange',dates:orange});

        // console.log('res:',res);
        let returnRes = res.concat(blue,orange,red,green);
        console.log('DATE ATTRIBUTES:',returnRes);
        return returnRes;
    },

  },
 }
</script>
<style>

div.adr-facilitator-dates-available-container{
  padding-left:50px;
  padding-top:50px;
  
}

:root {
  --day-content-height : 100px; 
  --day-content-width : 100px;
}

div.legend-available{
  --indicator-color:blue;
}

div.legend-confifmed{
  --indicator-color:green;
}

div.legend-tentative{
  --indicator-color:orange;
}

div.legend-error{
  --indicator-color:red;
}

div.legend-error{
  display:none;
}



div.calendar-legend-container{
  margin-top:10px;
  display:flex;
  max-width: 250px;
  max-height: 165px;
  flex-direction: column;
}

div.ubt-legend {
  margin-top:5px;
  max-height: 50px;
  flex:1;
  display:flex;
  align-items: left;
  justify-content: left;
  font-size:12px;
  padding-left:25px;
  position:relative;
}

div.ubt-legend::before{
  content:'';
  border: 1px solid var(--indicator-color);
  max-height: 20px;
  min-height: 20px;
  min-width: 20px;
  max-width: 20px;
  position:absolute;
  top:0px;
  left:0px;
  background-color:var(--indicator-color);
  border-radius:20px;
}



div.legend-error{
  display:none;
}



</style>


