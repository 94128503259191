import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// Vuetify
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
// import { VCalendar } from 'vuetify/labs/VCalendar';
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';


console.log('components:',components);

const vuetify = createVuetify({
  components:{...components,VCalendar},
  directives:directives,
});


const app = createApp(App);
app.use(store).use(VCalendar,{}).use(router).mount('#app');


