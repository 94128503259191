<template>
  <FullCalendar :options="calendarOptions">  
     <template v-slot:eventContent='arg'>
     <div class="adr-dispute-assignment" :class="getDisputeClass(arg.event.id)" @click="onEventClick(arg)">
           <div class="adr-dispute-assignment-facilitator" > {{ arg.event.title }} </div>
        <div class="adr-dispute-assignment-dispute">{{ getDisputeName(arg.event.id) }}</div>
        </div>
    </template>
  </FullCalendar>  
</template>

<script>

// https://vcalendar.io/getting-started/installation.html

import {mapState,mapGetters} from 'vuex';
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

export default {
  emits:[
    'on-day-click',
    'on-available-facilitator-click'
  ],
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  data() {
    return {
      currentDate:null,
    }
  },
  mounted() {

  },
  computed:{
    ...mapState([]),
    ...mapGetters(['facilitators','disputes','facilitatorDates','facilitatorUserIdField']),
    calendarOptions() {
      return {
        plugins: [ dayGridPlugin, interactionPlugin ],
        initialView: 'dayGridMonth',
        dateClick: this.onDateClick,
        events: this.events
      }
    },
    events() {
      let res = [];
      console.log('faciliatorDates:',this.facilitatorDates);
      for (let d in  this.facilitatorDates) {
        let date = this.facilitatorDates[d];
        console.log('date:',date);
        let color = 'green';
        let eventGroup = 'allocatedEvent';
        if (date.dispute_id === 0 && date.source === 'available') {
          color = 'blue';
          eventGroup = 'facilitatorAvailableDate'
        } else 
        if (date.dispute_id !== 0 && date.source === 'assigned') {
          color = 'orange';
          eventGroup = 'facilitatorAssigned'
        } else 
        if (date.dispute_id !== 0 && date.source === 'booked') {
          color = 'green';
          eventGroup = 'eventBooked'
        }
        let evt = {id:date.dispute_id,title:this.facilitators[date[this.facilitatorUserIdField]].name,date:date.date,color:color,facilitatorUserId:date[this.facilitatorUserIdField],eventDate:date.date,eventGroup:eventGroup}
        console.log('Added EVT:',evt);
        res.push(evt)
      }
      return res;
    }
  },
  methods:{
    onDateClick(evt) {
      this.currentDate = evt.date;
      this.$emit('on-day-click',evt.date);
    },
    getDisputeName(id) {
      console.log('getDisputeName:',id);
      if (id == 0) {
        return ''
      }
      else 
      {
        return this.disputes[id].reference;
      }
    },
    getDisputeClass(id) {
      return (id == 0) ? 'unassigned' : 'assigned';
    },
    onEventClick(evt) {
      console.log('Event:',evt.event);
      if (evt.event.extendedProps.facilitatorUserId)  {
        let facilitatorUserId = evt.event.extendedProps.facilitatorUserId;
        let eventDate = evt.event.extendedProps.eventDate;
        let eventGroup = evt.event.extendedProps.eventGroup;
        console.log('eventGroup:',eventGroup);
        if (eventGroup === 'facilitatorAvailableDate') {
          this.$emit('on-available-facilitator-click',{facilitatorUserId});
        }
        this.$emit('on-day-click',new Date(eventDate));
      }
    }
  }
}

</script>

<style>

.adr-dispute-assignment.unassigned{
  /* min-height: 40px; */
  max-height: 20px;
  min-height: 20px;
  cursor:pointer;
}

.adr-dispute-assignment.assigned{
  min-height: 40px;
  max-height: 40px;
}

.adr-dispute-assignment.assigned > .adr-dispute-assignment-dispute{
  font-weight: bold;
}

a.adr-dispute-assignment-facilitator{
 text-decoration: none;
 color:white;
}

</style>
